<template>
  <div class="his_main">
    <div class="right_btns">
      <qzf-button jz="false" button_code="lskjbb_scbb" type="warning" @click="saveBiao" size="small" v-loading.fullscreen.lock="fullscreenLoading">取数</qzf-button>
      <el-button size="small" @click="printing()" type="success" :disabled="!$buttonStatus('lskjbb_dy')">打印</el-button>
      <qzf-button jz="false" button_code="lskjbb_dc" size="small" @click="downLoad()" type="primary">导出</qzf-button>
      <el-date-picker @change="chagePeriod" v-model="periodF" type="month" value-format="YYYYMM" size="small" placeholder="日期" style="width: 160px;margin-left:6px;"></el-date-picker>
    </div>
    <!-- 小企业 -->
    <div v-if="type == '小企业会计准则2013版'">
      <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
        <!-- 资产负债表 -->
        <el-tab-pane label="资产负债表" name="1">
          <zcfzTable title="资产负债表" ref="zcfz" typeName='历史数据'></zcfzTable>
        </el-tab-pane>
        <!-- 资产负债表 end -->
        <!-- 利润表(季报) -->
        <el-tab-pane label="利润表(季报)" name="2">
          <lrbTable title="利润表(季报)" ref="lrb" typeName='历史数据'></lrbTable>
        </el-tab-pane>
        <!-- 利润表(季报) end -->
        <!-- 利润表(月报) -->
        <el-tab-pane label="利润表(月报)" name="3">
          <lrbTable title="利润表(月报)" ref="lrbMon" typeName='历史数据'></lrbTable>
        </el-tab-pane>
        <!-- 利润表(月报) end -->
      </el-tabs>
    </div>
    <!-- 小企业 -->
    <!-- 未执行新金融准则的一般企业 -->
    <div v-if="type == '一般企业会计准则' || type == '一般企业会计准则(全科目)'">
      <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
        <!-- 资产负债表 -->
        <el-tab-pane label="资产负债表" name="1">
          <zcfzTableTwo title="资产负债表" ref="zcfz" typeName='历史数据'></zcfzTableTwo>
        </el-tab-pane>
        <!-- 资产负债表 end -->
        <!-- 利润表(季报) -->
        <el-tab-pane label="利润表(季报)" name="2">
          <lrbTableTwo title="利润表(季报)" ref="lrb" typeName='历史数据'></lrbTableTwo>
        </el-tab-pane>
        <!-- 利润表(季报) end -->
        <!-- 利润表(月报) -->
        <el-tab-pane label="利润表(月报)" name="3">
          <lrbTableTwo title="利润表(月报)" ref="lrbMon" typeName='历史数据'></lrbTableTwo>
        </el-tab-pane>
        <!-- 利润表(月报) end -->
        <!-- 现金流量表 -->
        <!-- <el-tab-pane label="现金流量表" name="4">
          <cashTable ref="cash" typeName='历史数据'></cashTable>
        </el-tab-pane> -->
        <!-- 利润表季报 end -->
      </el-tabs>
    </div>
    <!-- 未执行新金融准则的一般企业 end -->
    <div v-if="type == '企业会计制度'">
      <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
        <!-- 资产负债表 -->
        <el-tab-pane label="资产负债表" name="1">
          <zcfzTableThree title="资产负债表" ref="zcfz" typeName='历史数据'></zcfzTableThree>
        </el-tab-pane>
        <!-- 资产负债表 end -->
        <!-- 利润表(季报) -->
        <el-tab-pane label="利润表(季报)" name="2">
          <lrbTableThree title="利润表(季报)" ref="lrb" typeName='历史数据'></lrbTableThree>
        </el-tab-pane>
        <!-- 利润表(季报) end -->
        <!-- 利润表(月报) -->
        <el-tab-pane label="利润表(月报)" name="3">
          <lrbTableThree title="利润表(月报)" ref="lrbMon" typeName='历史数据'></lrbTableThree>
        </el-tab-pane>
        <!-- 利润表(月报) end -->
        <!-- 现金流量表 -->
        <!-- <el-tab-pane label="现金流量表" name="4">
          <cashTable ref="cash" typeName='历史数据'></cashTable>
        </el-tab-pane> -->
        <!-- 利润表季报 end -->
      </el-tabs>
    </div>

    <!-- 已执行新金融准则的一般企业 end -->
    <div v-if="type == '一般企业会计准则(已执行新金融准则)'">
      <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
        <el-tab-pane label="资产负债表" name="1">
          <zcfzTableFour title="资产负债表" ref="zcfz" typeName='历史数据'></zcfzTableFour>
        </el-tab-pane>
        <el-tab-pane label="利润表(季报)" name="2">
          <lrbTableFour title="利润表(季报)" ref="lrb" typeName='历史数据'></lrbTableFour>
        </el-tab-pane>
        <el-tab-pane label="利润表(月报)" name="3">
          <lrbTableFour title="利润表(月报)" ref="lrbMon" typeName='历史数据'></lrbTableFour>
        </el-tab-pane>
      </el-tabs>
    </div>

    <div v-if="type == '民办非'">
      <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
        <!-- 资产负债表 -->
        <el-tab-pane label="资产负债表" name="1">
          <balanceBusiness title="资产负债表" ref="zcfz" typeName='历史数据'></balanceBusiness>
        </el-tab-pane>
        <!-- 资产负债表 end -->
        <!-- 利润表(季报) -->
        <el-tab-pane label="业务活动表(季报)"  name="2">
          <businessSeason title="利润表(季报)" ref="lrb" typeName='历史数据'></businessSeason>
        </el-tab-pane>
        
        <!-- 利润表(季报) end -->
        <!-- 利润表(月报) -->
        <el-tab-pane label="业务活动表(月报)" name="3">
          <businessSheet title="利润表(月报)" ref="lrbMon" typeName='历史数据'></businessSheet>
        </el-tab-pane>
        <!-- 利润表(月报) end -->
        <!-- 现金流量表 -->
        <!-- <el-tab-pane label="现金流量表" name="4">
          <cashTable ref="cash" typeName='历史数据'></cashTable>
        </el-tab-pane> -->
        <!-- 利润表季报 end -->
      </el-tabs>
    </div>
    <!-- 农村合作社 -->
    <div v-if="type == '农业合作社'">
      <el-tabs v-model="activeName" style="height: 100%;"  @tab-click="handleClick" type="border-card">
        <el-tab-pane label="资产负债表" name="1">
          <nchzsZcfz ref="zcfz" typeName='历史数据'></nchzsZcfz>
        </el-tab-pane>
        <el-tab-pane label="盈余及盈余分配表" name="2">
          <nchzsLrb ref="nchzsYyfp" title="盈余及盈余分配表" typeName='历史数据'></nchzsLrb>
        </el-tab-pane>
        <el-tab-pane label="成员权益变动表" name="4">
          <nchzsCyqy ref="nchzsCyqy" title="成员权益变动表" typeName='历史数据'></nchzsCyqy>
        </el-tab-pane>
        
      </el-tabs>
    </div>
  </div>
</template>

<script>
import balanceBusiness from '@/views/report/finance/tables/balanceBusiness' //民办非资产负债
import businessSheet from '@/views/report/finance/tables/businessSheet' //民办非业务活动表（月报）
import businessSeason from '@/views/report/finance/tables/businessSeason' //民办非业务活动表（季报）

import zcfzTable from '@/views/report/finance/tables/zcfzTable' //小企业资产负债表
import zcfzTableTwo from '@/views/report/finance/tables/zcfzTable2' //未执行新金融准则的一般企业的资产负债表
import zcfzTableThree from '@/views/report/finance/tables/zcfzTable3' //企业会计制度的资产负债表
import zcfzTableFour from '@/views/report/finance/tables/zcfzTable4' //已执行新金融准则的一般企业的资产负债表

import lrbTable from '@/views/report/finance/tables/lrbTable' //小企业利润表
import lrbTableTwo from '@/views/report/finance/tables/lrbTable2' //未执行新金融准则的一般企业的利润表
import lrbTableThree from '@/views/report/finance/tables/lrbTable3' //企业会计制度的利润表
import lrbTableFour from '@/views/report/finance/tables/lrbTable4' //已执行新金融准则的一般企业的利润表

// 农村合作社
import nchzsZcfz from "./tables/nchzsZcfz.vue"
import nchzsLrb from "./tables/nchzsLrb.vue"
import nchzsCyqy from "./tables/nchzsCyqy.vue"

import { hisKjSave } from '@/api/old'
import { report,exportReport } from "@/api/printSet"

export default {
  name: 'historyFinance',
  components: { 
    balanceBusiness,
    businessSheet,
    businessSeason,
    zcfzTable,
    lrbTable,
    zcfzTableTwo,
    lrbTableTwo,
    zcfzTableThree,
    lrbTableThree,
    zcfzTableFour,
    lrbTableFour,
    nchzsZcfz,
    nchzsLrb,
    nchzsCyqy
  },
  data() {
    return {
      showStatus: false,
      fullscreenLoading: false,
      type: this.$store.getters["user/comInfo"].comKj,
      periodLrb: '',
      periodLrb2: '',
      durationOfTaxTime: '',
      activeName: '1',
      name: '资产负债表',
      qslyList: [],
      // comName: ''
      taxNo: '',
      contentStyleObj:{}, //高度变化
      periodF:this.$store.getters['user/comInfo'].period,
      period:this.$store.getters['user/comInfo'].period
    }
  },
  created() {
    // 获取窗口高度
    this.contentStyleObj=this.$getHeight(356)
    window.addEventListener('resize', this.getHeight);
  },
  methods: {
    // 切换账期
    chagePeriod() {
      this.period = this.periodF
      if(this.activeName == 1){
        this.$refs.zcfz.getList(this.period)
      }else if(this.activeName == 2){
        if(this.type == '农业合作社'){
          this.$refs.nchzsYyfp.getList(this.period)
        }else{
          this.$refs.lrb.getList(this.period)
        }
      }else if(this.activeName == 3){
        this.$refs.lrbMon.getList(this.period)
      }else if(this.activeName == 4){
        this.$refs.nchzsCyqy.getList(this.period)
      }
    },
    printing(){
      if(this.activeName == '1'){
        this.bookNames = 'kj_zcfz'
      }else if(this.activeName == '2'){
        this.bookNames = 'his_lrb_q'
        this.mqy = 'q'
      }else if(this.activeName == '3'){
        this.bookNames = 'kj_lrb'
        this.mqy = 'm'
      }else if(this.activeName == '4'){
        this.bookNames = 'his_lrb_m'
        this.mqy = 'y'
      }
      let param = {
        comIds:[this.$store.getters['user/comInfo'].comId],
        period:this.$store.getters['user/comInfo'].period,
        bookNames:[this.bookNames],
        mqy:this.mqy
      }
      report(param).then(res=>{
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    },
    downLoad(){
      if(this.activeName == '1'){
        this.bookNames = 'gs_cwbb'
      }else if(this.activeName == '2'){
        this.bookNames = 'gs_cwbb'
        this.mqy = 'q'
      }else if(this.activeName == '3'){
        this.bookNames = 'gs_cwbb'
        this.mqy = 'm'
      }else if(this.activeName == '4'){
        this.bookNames = 'gs_cwbb'
        this.mqy = 'y'
      }
      let param = {
        comIds:[this.$store.getters['user/comInfo'].comId],
        period:this.$store.getters['user/comInfo'].period,
        bookNames:[this.bookNames],
        mqy:this.mqy
      }
      exportReport(param).then(res=>{
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    },

    closeSubject() {
      this.showStatus = false
    },
    saveBiao(){
      if(!this.periodF){
        this.$message.error('请选择账期')
        return
      }
      this.fullscreenLoading = true;
      hisKjSave({period:this.period}).then(res => {
        if(res.data.msg == "success"){
          this.$qzfMessage("成功", 3)
          this.$refs.zcfz.getList()
          if(this.type == '农业合作社'){
            this.$refs.nchzsYyfp.getList();
            this.$refs.nchzsCyqy.getList()
          }else{
            this.$refs.lrb.getList()
            this.$refs.lrbMon.getList()
          }
        } 
        this.fullscreenLoading = false;
      })
    },
    handleClick(tab, event) {
      if(tab.props.label == '资产负债表'){
        this.name = tab.label
        this.$refs.zcfz.getList()
      }else if(tab.props.label == '利润表(季报)' || tab.props.label == '业务活动表(季报)') {
        this.name = tab.label
        this.$refs.lrb.getList()
      }else if(tab.props.label == '利润表(月报)' || tab.props.label == '业务活动表(月报)') {
        this.name = tab.label
        this.$refs.lrbMon.getList()
      }else if(tab.props.label == '盈余及盈余分配表'){
        this.name = tab.label
        this.$refs.nchzsYyfp.getList()
      }else if(tab.props.label == '成员权益变动表'){
        this.name = tab.label
        this.$refs.nchzsCyqy.getList()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.his_main{
  position: relative;
  background-color: #f1f1f1;
  .right_btns{
    position: absolute;
    top: 7px;
    right: 40px;
    z-index: 99;
  }
}
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
:deep(.el-tabs__content){
  background-color: #f1f1f1 !important;
}
</style>
